import React from 'react';
import styled from '@emotion/styled';
import {Container, Grid, Box, Button, Link} from '@mui/material'
import MovingLogo from '../components/MovingLogo';
import Experiment from '../components/Experiment';

const Header = styled('div')`
  font-size: 40px;
  margin-top: 24px;
  margin-bottom: 24px;
  text-align: center;
`;

const Subtitle = styled('div')`
  margin: 0px;
  font-size: 16px;
`;

const HeaderBox = styled('div')`
  display: inline;
  vertical-align: middle;
  width: 100%;
  text-align: center;
`;


// background: #d7f31f;
// box-shadow: 0px 0px 5px 5px #d7f31f;
const Strip = styled(Container)`
  margin-top: 24px;
  margin-bottom: 24px;
  min-width: 360px;
  `;

const Story = styled('div')`
  paragraph-spacing: 1.5;
  margin: 24px;
  font-size: 18px;
  padding-bottom: 24px;
  line-height: 2;
  text-align: left;
`;


export default function HomePage() {
  return (
    <Strip maxWidth="xs">
      <Grid container>
        <Grid container spacing={0} marginTop={1} textAlign="center">
          <Grid item xs={3}>
            <MovingLogo width={80} />
          </Grid>
          <Grid item xs={3}>
            <MovingLogo width={80} />
          </Grid>
          <Grid item xs={3}>
            <MovingLogo width={80} />
          </Grid>
          <Grid item xs={3}>
            <MovingLogo width={80} />
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <HeaderBox>
            <Header>
              The Onchains
              <Subtitle>
                Pioneers of Basetown
              </Subtitle>
            </Header>
          </HeaderBox>
        </Grid>

      </Grid>
      
      <Experiment />
      <Story>
        <p>
          The Onchains are 10,000 fearless NFTs of Basetown. 
          They are a symbol of being an onchain pioneer. And just like you, 
          they enjoy exploring new onchain adventures!
        </p>
        <p>
          Each Onchain has their DNA encoded on Base and their colors and shapes are genetic 
          features making them unique. Onchain genetics also give them the ability to 
          create Onchainlings, passing on their traits to future generations.
        </p>
        <p>
          In the near future, they will come together to pioneer new Onchain social experiments.
        </p>
        <p>
          Today, mint original Onchains! What will your Onchains look like? Join us and find out!
        </p>
      </Story>
      <Box padding={2} paddingBottom={10}>
        <Box paddingBottom={1}>
          Already have an Onchain? 
        </Box>

        <Button href='./Onchainlings' fullWidth variant='contained'>
          Grow your Family with Onchainlings
        </Button>
      </Box>
      <Box padding={2} paddingBottom={10} color="gray">
        made by <Link color="#aaa" href="https://twitter.com/cagriaksay" target="_blank">cagriaksay</Link> 
      </Box>
    </Strip>

  );
}
