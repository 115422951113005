/**
*
* Onchain
*
*/

import React from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import {drawOnchain, stringToGenes} from '../javascripts/OnChainDrawer';
import { SVG } from '@svgdotjs/svg.js'
import '@svgdotjs/svg.filter.js'

const OnchainFrame = styled('div')`
  margin: auto;
  overflow: hidden;
  text-align: center;
`;

class Onchain extends React.Component {
  
  render() {
    const { width, genome } = this.props;
    const draw = (ref) => {
      if (ref === null) return;
      while (ref.firstChild) ref.removeChild(ref.firstChild);
      
      const canvas = SVG().addTo(ref)
      drawOnchain(canvas, width, stringToGenes(genome))
    }
    return (
      <OnchainFrame width={width} className={this.props.className} ref={(ref) => draw(ref)} />
    );
  }
}

Onchain.propTypes = {
  width: PropTypes.number.isRequired,
  genome: PropTypes.string.isRequired,
  className: PropTypes.string,
  id: PropTypes.string,
};

export default Onchain;
