import React, {useState, useEffect} from 'react';
import styled from '@emotion/styled';
import { stringToGenes, genesToString, crossBreed, randomGenes } from '../javascripts/OnChainDrawer';
import {Box} from '@mui/material'
import Onchain from '../components/Onchain';

const Header = styled('div')`
  font-size: 40px;
  margin-top: 24px;
  margin-bottom: 24px;
  text-align: center;
`;

export default function Demo() {

  const [genome, setGenome] = useState(genesToString(randomGenes()));

  useEffect(() => {
    let timerFunc = setInterval(() => {
      const fgenes = stringToGenes(genome);
      const genes = genesToString(crossBreed(fgenes, fgenes));
      setGenome(genes);
    }, 254);
    return () => clearInterval(timerFunc);
}, [genome]);


  return (
    <Box marginTop={10}>
      <Onchain width={512} genome={genome} />
    </Box>
  );
}
