/**
*
* Logo
*
*/

import React, {useEffect, useState} from 'react';
import styled from '@emotion/styled';
import { stringToGenes, genesToString, randomGenes, crossBreed } from '../javascripts/OnChainDrawer';
import Onchain from './Onchain';


const Frame = styled('div')`
  display: inline-block;
  overflow: hidden;
`;

export default function MovingLogo({width}) {

  const [genome, setGenome] = useState(genesToString(randomGenes()));

  useEffect(() => {
      let timerFunc = setInterval(() => {
        const fgenes = stringToGenes(genome);
        const genes = genesToString(crossBreed(fgenes, fgenes));
        setGenome(genes);
      }, 400);
      return () => clearInterval(timerFunc);
  }, [genome]);

  return (
    <Frame>
      <Onchain genome={genome} width={width} />
    </Frame>
  );

}
