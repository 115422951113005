import React from 'react';
import { Route, Routes, BrowserRouter } from 'react-router-dom';

import Homepage from './containers/Homepage';
import Onchainlings from './containers/Onchainlings';
import NotFoundPage from './containers/NotFound';


import '@rainbow-me/rainbowkit/styles.css';
import {
  getDefaultWallets,
  RainbowKitProvider,
} from '@rainbow-me/rainbowkit';
import { configureChains, createConfig, WagmiConfig } from 'wagmi';
import {  base, baseGoerli } from 'wagmi/chains';
import { jsonRpcProvider } from '@wagmi/core/providers/jsonRpc';
import { publicProvider } from 'wagmi/providers/public';
import Demo from './containers/Demo';
import { WEB3_PROVIDER } from './constants';

const { chains, publicClient } = configureChains(
  [base],
  [
    jsonRpcProvider({
      rpc: () => ({
        http: WEB3_PROVIDER,
      }),
    }),
    publicProvider(),
  ]
);

const { connectors } = getDefaultWallets({
  appName: 'Onchains',
  projectId: 'c2c8edbac9357f63e0dd3288e08550b5',
  chains
});

export const wagmiConfig = createConfig({
  autoConnect: true,
  connectors,
  publicClient
})



export default function App() {

  return (
    <WagmiConfig config={wagmiConfig}>
      <RainbowKitProvider chains={chains}>
        <BrowserRouter>
          <Routes>
            <Route path="/" element={<Homepage />} />
            <Route path="/Onchainlings" element={<Onchainlings />} />
            <Route path="/Demo" element={<Demo />} />
            <Route element={<NotFoundPage />} />
          </Routes>
        </BrowserRouter>
      </RainbowKitProvider>
    </WagmiConfig>
  );
}
